/** @jsx jsx */
import React, { Fragment, memo } from "react";
import { Maybe } from "shared/utils/ts";
import { jsx } from "theme-ui";

import AccordionRow from "./AccordionRow";

type AccordionRow = {
  readonly title?: string;
  readonly content?: string;
};

interface Props {
  rows: Maybe<ReadonlyArray<Maybe<AccordionRow>>> | undefined;
  isInitialCollapsed?: boolean;
  isCollapsible?: boolean;
}

const Accordion: React.FC<Props> = ({
  rows,
  isInitialCollapsed = true,
  isCollapsible = true,
}) => {
  return (
    <Fragment>
      {rows?.map((row, index) => {
        return (
          <AccordionRow
            key={`accordion-${index}`}
            isInitialCollapsed={isInitialCollapsed}
            isCollapsible={isCollapsible}
            title={row?.title}
            content={row?.content}
          />
        );
      })}
    </Fragment>
  );
};

export default memo(Accordion);
