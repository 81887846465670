/** @jsx jsx */
import { graphql } from "gatsby";
import { Accordion } from "gatsby-theme-accordion";
import React, { memo } from "react";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetAccordion" }>;
}

const AccordionWidget: React.FC<Props> = ({
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <Accordion
        rows={widget.accordionShape?.rows}
        isCollapsible={widget.accordionShape?.collapsible}
        isInitialCollapsed={widget.accordionShape?.collapsed}
      />
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetAccordion on WidgetAccordion {
    id
    __typename
    accordionShape {
      collapsed
      collapsible
      rows {
        content
        title
      }
    }
  }
`;

export default memo(AccordionWidget);
