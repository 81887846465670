import { getMostReadable, getMostVisible } from "shared/helpers/colors";
import { ThemeUIStyleObject } from "theme-ui";

type sxStyleFunction = (
  expanded: boolean,
  isCollapsible: boolean
) => ThemeUIStyleObject;

const row: sxStyleFunction = () => ({
  alignItems: "center",
  borderTopColor: (theme) =>
    getMostVisible(theme.rawColors?.background, [
      theme.rawColors?.primary,
    ]).toHexString(),
  borderTopStyle: "solid",
  borderTopWidth: "thin",
  color: "text",
  padding: 3,
  position: "relative",
});

const rowContainer: sxStyleFunction = (isCollapsible) => ({
  display: "flex",
  fontWeight: "bold",
  alignItems: "center",
  fontSize: 3,
  cursor: isCollapsible ? "pointer" : "unset",
});

const rowLabel: sxStyleFunction = () => ({
  flex: 1,
  paddingRight: 2,
});

const rowIcon: sxStyleFunction = () => ({
  flex: "0 0 auto",
  fontSize: 4,
  textAlign: "center",
  borderRadius: "50%",
  width: "1.5em",
  height: "1.5em",
  backgroundColor: (theme) =>
    getMostVisible(theme.rawColors?.background, [
      theme.rawColors?.primary,
    ]).toHexString(),
  color: (theme) =>
    getMostReadable(
      getMostVisible(theme.rawColors?.background, [
        theme.rawColors?.primary,
      ]).toHexString()
    ).toHexString(),
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const rowContent: (
  expanded: boolean,
  isCollapsible: boolean
) => ThemeUIStyleObject = () => ({
  paddingTop: 2,
});

const sxStyles: {
  row: sxStyleFunction;
  rowContainer: sxStyleFunction;
  rowContent: sxStyleFunction;
  rowIcon: sxStyleFunction;
  rowLabel: sxStyleFunction;
} = {
  row,
  rowContainer,
  rowContent,
  rowIcon,
  rowLabel,
};

export default sxStyles;
