import React, { memo } from "react";
import { Icon, mdiChevronDown, mdiChevronUp } from "shared/components/Icon";

interface Props {
  expanded: boolean;
}

const AccordionIcon: React.FC<Props> = ({ expanded }) => (
  <Icon
    path={expanded ? mdiChevronUp : mdiChevronDown}
    size={1}
    color={"currentColor"}
  />
);

export default memo(AccordionIcon);
