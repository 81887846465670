/** @jsx jsx */
import React, { memo, useState } from "react";
import ParsedHTML from "shared/components/ParsedHTML";
import { jsx } from "theme-ui";

import AccordionIcon from "./AccordionIcon";
import sxStyles from "./styles";

interface Props {
  isInitialCollapsed: boolean;
  isCollapsible: boolean;
  title?: string;
  content?: string;
}

const AccordianComponent: React.FC<Props> = ({
  isInitialCollapsed,
  isCollapsible,
  title,
  content,
}) => {
  const [expanded, setExpanded] = useState(!isInitialCollapsed);

  return (
    <div sx={sxStyles.row(expanded, isCollapsible)}>
      <div
        sx={sxStyles.rowContainer(expanded, isCollapsible)}
        onClick={
          isCollapsible
            ? () => {
                setExpanded(!expanded);
              }
            : undefined
        }
      >
        <div sx={sxStyles.rowLabel(expanded, isCollapsible)}>{title}</div>

        {isCollapsible ? (
          <div sx={sxStyles.rowIcon(expanded, isCollapsible)}>
            <AccordionIcon expanded={expanded} />
          </div>
        ) : null}
      </div>

      {(expanded || !isCollapsible) && (
        <div sx={sxStyles.rowContent(expanded, isCollapsible)}>
          <ParsedHTML>{content || ""}</ParsedHTML>
        </div>
      )}
    </div>
  );
};

export default memo(AccordianComponent);
